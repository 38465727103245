$font-size-2: 1.6rem;
$font-size-3: 1.4rem;

$font-headline-2: $font-size-2;

$font-body: $font-size-3;

$font-weight: (
  'regular': 300,
  'bold': 700,
);

@mixin text-subtitle {
  font-size: $font-headline-2;
  font-weight: map-get($font-weight, bold);
}

@mixin text-body {
  font-size: $font-body;
  font-weight: map-get($font-weight, regular);
}

.toaster {
  position: fixed;
  bottom: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

.toast {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding: 1.6rem;
  margin-bottom: 1rem;
  min-height: 5.2rem;
  width: 52rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.12),
    0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.18);
  letter-spacing: -0.02rem;

  &__title {
    @include text-subtitle();
    margin-bottom: 0.6rem;
  }

  &__text {
    @include text-body();
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 4rem;
  }

  &--success &__timer,
  &--warning &__timer,
  &--error &__timer {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.4rem;
    animation-name: progress;
    -moz-animation-name: progress;
    -webkit-animation-name: progress;
    -o-animation-name: progress;
    animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -o-animation-timing-function: linear;
  }

  &--success &__timer {
    background-color: #34a281;
  }
  &--warning &__timer {
    background-color: #f35b1c;
  }
  &--error &__timer {
    background-color: #e3323d;
  }

  &__cancel {
    cursor: pointer;
    border: none;
    background-color: inherit;
    float: right;
    height: 4rem;
    width: 4rem;
  }

  &-item {
    &-enter {
      opacity: 0;
      height: 0;

      &-active {
        opacity: 1;
        height: auto;
        transition: opacity 500ms ease-out, height 200ms ease-out;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        transition: opacity 500ms ease-out;
      }
    }
  }
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-moz-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-o-keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
