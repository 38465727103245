* {
  font-family: "museo-sans", sans-serif;
  font-weight: 300;
}

body {
  background-color: #f5f5f6;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
