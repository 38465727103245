.form {
  margin: 0 auto 1.6rem;
  width: 51.2rem;
  background: #ffffff;
  border-radius: 0.8rem;
  padding: 2.4rem 8.8rem;

  &--full-width {
    width: 100%;
  }

  &--pull-right {
    float: right;
  }

  &-register-title{
    padding-bottom: 1.2rem;
  }

  &__separator {
    position: relative;
    text-align: center;
  }

  &__separator:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background: #dddddd;
  }

  &__separator-text {
    position: relative;
    background: white;
    padding: 0.4rem 2.4rem;
    display: inline-block;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__col {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__col:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }

  & label {
    width: 100%;
  }

  & a {
    color: #257883;
    text-decoration: none;
  }

  .select__menu {
    max-height: 25rem;
  }
  .select__menu-list {
    max-height: 20rem;
  }
}

.message {
  margin: 0 auto 1.6rem;
  width: 51.2rem;
  background: #fff;
  border-radius: 1.6rem;
  padding: 2.4rem;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  &__body {
    margin: 1.6rem auto;

    &--center{
      margin-top: 1.6rem;
      display: flex;
      justify-content: center;
    }
  }

  &__btn {
    margin-top: 1.6rem;
    float: right;
  }
}

.brand-logo {
  display: grid;
  margin: 4rem auto 3rem;
  justify-content: center;
}

.footer {
  width: 51.2rem;
  margin: 1rem auto 5rem;
  padding: 0 1.6rem;
  display: flex;
  justify-content: flex-end;
  grid-gap: 3.2rem;

  &__link {
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
    color: rgba(55, 62, 78, 0.73);
  }
}
