@import url(https://use.typekit.net/foe6iuf.css);
html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 700;
}

* {
  font-family: "museo-sans", sans-serif;
  font-weight: 300;
}

body {
  background-color: #f5f5f6;
}

.form{margin:0 auto 1.6rem;width:51.2rem;background:#ffffff;border-radius:0.8rem;padding:2.4rem 8.8rem}.form--full-width{width:100%}.form--pull-right{float:right}.form-register-title{padding-bottom:1.2rem}.form__separator{position:relative;text-align:center}.form__separator:before{content:'';position:absolute;left:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:100%;height:1px;background:#dddddd}.form__separator-text{position:relative;background:white;padding:0.4rem 2.4rem;display:inline-block}.form__row{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between}.form__col{width:100%;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.form__col:not(:last-of-type){margin-bottom:1.6rem}.form label{width:100%}.form a{color:#257883;text-decoration:none}.form .select__menu{max-height:25rem}.form .select__menu-list{max-height:20rem}.message{margin:0 auto 1.6rem;width:51.2rem;background:#fff;border-radius:1.6rem;padding:2.4rem}.message::after{content:'';display:table;clear:both}.message__body{margin:1.6rem auto}.message__body--center{margin-top:1.6rem;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center}.message__btn{margin-top:1.6rem;float:right}.brand-logo{display:grid;margin:4rem auto 3rem;-webkit-justify-content:center;justify-content:center}.footer{width:51.2rem;margin:1rem auto 5rem;padding:0 1.6rem;display:-webkit-flex;display:flex;-webkit-justify-content:flex-end;justify-content:flex-end;grid-gap:3.2rem}.footer__link{text-decoration:none;font-weight:700;font-size:1.2rem;color:rgba(55,62,78,0.73)}

.toaster{position:fixed;bottom:1rem;list-style:none;padding:0;margin:0;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.toast{position:relative;overflow:hidden;background-color:#ffffff;padding:1.6rem;margin-bottom:1rem;min-height:5.2rem;width:52rem;border-radius:0.4rem;box-shadow:0 0.1rem 0.8rem 0 rgba(0,0,0,0.12),0 0.1rem 0.2rem 0 rgba(0,0,0,0.18);letter-spacing:-0.02rem}.toast__title{font-size:1.6rem;font-weight:700;margin-bottom:0.6rem}.toast__text{font-size:1.4rem;font-weight:300}.toast__data{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:flex-start;align-items:flex-start;min-height:4rem}.toast--success .toast__timer,.toast--warning .toast__timer,.toast--error .toast__timer{position:absolute;top:0;left:0;height:0.4rem;animation-name:progress;-moz-animation-name:progress;-webkit-animation-name:progress;-o-animation-name:progress;animation-timing-function:linear;-moz-animation-timing-function:linear;-webkit-animation-timing-function:linear;-o-animation-timing-function:linear}.toast--success .toast__timer{background-color:#34a281}.toast--warning .toast__timer{background-color:#f35b1c}.toast--error .toast__timer{background-color:#e3323d}.toast__cancel{cursor:pointer;border:none;background-color:inherit;float:right;height:4rem;width:4rem}.toast-item-enter{opacity:0;height:0}.toast-item-enter-active{opacity:1;height:auto;transition:opacity 500ms ease-out, height 200ms ease-out}.toast-item-exit{opacity:1}.toast-item-exit-active{transition:opacity 500ms ease-out}@keyframes progress{from{width:0}to{width:100%}}@-webkit-keyframes progress{from{width:0}to{width:100%}}

*{font-family:"museo-sans", sans-serif;font-weight:300}body{background-color:#f5f5f6}.loading{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;height:100vh}

